.footerCon {
    background: #000;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    padding: 4rem 15%;
  
    .footerLogo {
      width: 20%;
      margin-bottom: 1rem;
      img {
        width: 100%;
      }
    }
  
    nav {
      justify-self: center;
      ul {
      }
  
      li {
        list-style: none;
        display: inline-block;
        padding: 1rem 2rem;
        cursor: pointer;
      }
  
      a {
        text-decoration: none;
        color: white;
        font-size: 1.5rem;
        text-transform: uppercase;
      }
    }
  
    .footerSocial {
      margin: 1.5rem;
      .footerSocialIcon {
        font-size: 3rem;
        margin: 0 0.5rem;
        color: white;
        cursor: pointer;
  
        &:hover {
          color: #e4e4e4;
        }
      }
    }
  
    .footerCopyright {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, -50%);
  
      .footerCopyrightText {
        font-size: 1.3rem;
      }
    }
  }