@import "variables";

.loginCon {
  padding: 8rem 7.5%;

  h1 {
    font-size: calc(2rem + 2em);
    padding-bottom: 4rem;
  }

  input {
    display: block;
    padding: 1.5rem;
    margin-bottom: 2rem;
    width: 30rem;
    font-size: calc(0.5rem + 0.5vw);
  }

  label {
    font-size: calc(0.6rem + 0.6vw);
  }

  button {
    padding: 2rem 5rem;
  }
}

.adminLogout {
  border: none;
  background: none;
  margin-right: 8rem;
  padding-bottom: 0 !important;
}

// ** KONTAKT ADMIN ** //
// ** START ** //
.contactAdminCon {
  padding: 4rem 7.5% 8rem 7.5%;

  table {
    border-collapse: collapse;
    margin: 0 auto;

    th {
      font-size: calc(0.5rem + 0.5vw);
      padding: 0.5rem 0;
      text-align: left;
    }

    tr {
      width: fit-content;
    }

    td {
      padding: 1.5rem;
      border: 1px solid rgba(128, 128, 128, 0.479);
      font-size: calc(0.6rem + 0.6vw);
      color: $davy-gray;
      overflow: hidden;
    }

    .createIcon,
    .deleteIcon,
    .editIcon {
      font-size: calc(1rem + 1vw);
      cursor: pointer;
    }

    .contactAdminTable:hover {
      background-color: rgba(128, 128, 128, 0.25);
    }

    .contactNotRead {
      background-color: darkred;
      color: white;
    }

    .contactRead {
      background-color: darkgreen;
      color: white;
    }
  }
}

// ** END ** //

.navigationAdmin {
  display: flex;
  align-content: center;
  justify-content: center;
  z-index: 100;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;

  ul {
    display: flex;
    gap: 6rem;
    padding: 3rem 20rem;
  }

  li {
    list-style: none;
    display: inline-block;
    position: relative;
    // opacity: 0;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    display: inline-block;
    position: relative;
    padding: 1rem;
  }

  a {
    text-decoration: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: black;
    font-family: $font_main;
  }
  a::after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    // background: rgb(187, 185, 185);
    background: white;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  a:hover::after {
    width: 100%;
    left: 0;
  }

  // .active {
  //   color: #8a8989;
  //   color: #525252;
  // }
}