@import 'variables.scss';

.navigation {
  // width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  // margin: 4rem auto;
  // position: absolute;
  // left: 5%;
  z-index: 100;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;

  ul {
    display: flex;
    gap: 6rem;
    // border-bottom: 1px solid #8a898928;
    padding: 3rem 20rem;
  }

  li {
    list-style: none;
    display: inline-block;
    position: relative;
    // opacity: 0;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    display: inline-block;
    position: relative;
    padding: 1rem;
  }

  a {
    text-decoration: none;
    font-size: 1.5rem;
    cursor: pointer;
    // color: rgb(187, 185, 185);
    // color: #eeeeee;
    color: white;
    font-family: $font_main;
    // font-weight: 600;
  }
  a::after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    // background: rgb(187, 185, 185);
    background: white;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  a:hover::after {
    width: 100%;
    left: 0;
  }

  // .active {
  //   color: #8a8989;
  //   color: #525252;
  // }
}