@import url("https://fonts.googleapis.com/css?family=Fira+Mono:400");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

// Fonts
// $font_main: 'Raleway', sans-serif;
$font_main: 'Poppins', sans-serif;
$font_second: 'Poppins', sans-serif;

// VARIABLER
// colors
$silver: #AAAAAA;
$dim-gray: #666666;
$davy-gray: #555555;
$delft-blue: #293B5F;
$dark-purple: #261C2C;
$blush: #D45D79;